import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { BillingAddressContainer } from './components/BillingAddress';
import { PaymentInformationPreviewContainer } from './preview/PaymentInformationPreview';
import { PreviousButton } from './components/NavigationButtons';
import { connect } from 'react-redux';
import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';
import { getSelectedResourceIds } from './resourceSelection/redux/resourceSelection.redux';
import { reportErrorToSentry } from '../../../configuration/setup/sentry';
import { CheckoutBuyButton } from './preview/CheckoutBuyButton';
import { useNavigate } from 'react-router-dom';
import { subscribeThunk } from './thunks/subscription.thunk';
import { withLoadingAndErrorState } from '../common/error/LoadingAndErrorStateWrapper';
import { getHasError, getIsLoading } from '../api/redux/api.redux';
import { ApiCalls } from '../api/redux/types';
import { fetchBookableResourcesThunk } from './resourceSelection/resourceSelection.thunk';
import { compose } from 'redux';
import {
    CheckoutSteps,
    GACheckoutStepData,
    getGACheckoutStepData,
    getGAEventCheckoutProgress,
    getGAEventTermsAccepted,
} from './checkoutGTMUtils';
import { safeDataLayerPush } from '../common/utils/googleTagManagerWrapper';
import { Product } from './redux/types';
import { getSelectedProduct, getSelectedProductLevel } from './redux/checkout.redux';
import { MultiLevelPriceDetailsContainer } from './preview/MultiLevelPriceDetails';
import { SelectedResourcesOverviewContainer } from './components/SelectedResourcesOverviewContainer';
import { TermsAndConditionsContainer } from '../common/termsAndConditions/TermsAndConditionsContainer';
import { PrivacyPolicyOpener } from '../common/PrivacyPolicyOpener';
import { isFleetProductId } from '../config/fleetProduct';
import { RootDispatch, RootState } from '../../../configuration/setup/store';
import { useRedirectToResourceSelection } from './deeplinking/CheckoutRouteHelper';
import { getIsEligibleForFreeTrial, isFreeTrialProduct } from '../freeTrial/redux/freeTrial.redux';
import { CheckoutFreeTrialWarning } from './CheckoutFreeTrialWarning';
import { isPurchaseFree } from './CheckoutService';
import { isBrazilianTenant } from '../common/utils/tenants';

interface OwnProps {
    previousPath: string;
    nextPath: string;
}

interface Props extends OwnProps {
    product?: Product;
    productLevel?: string;
    resourceIds: Array<string>;
    performCheckout: () => void;
    gAData: GACheckoutStepData;
    showFreeTrialWarning: boolean;
    showBillingAndPaymentDetails: boolean;
}

const CheckoutPreviewPage = (props: Props) => {
    const {
        nextPath,
        previousPath,
        performCheckout,
        product,
        productLevel,
        gAData,
        showFreeTrialWarning,
        showBillingAndPaymentDetails,
    } = props;

    const [termsAccepted, setTermsAccepted] = useState(false);
    const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
    const isFleetProduct = isFleetProductId(product?.sku);
    const navigate = useNavigate();
    const intl = useIntl();
    useRedirectToResourceSelection();

    useEffect(() => {
        if (gAData.product) {
            safeDataLayerPush(() => getGAEventCheckoutProgress(gAData));
        }
        // eslint-disable-next-line
    }, [gAData.product, gAData.numberOfSelectedResources]);

    if (!product) {
        reportErrorToSentry(new Error('CheckoutPreviewPage does not have a product prop defined'));
        return <ErrorState headline={<FormattedMessage id='marketplace.checkout.error' />} />;
    }

    return (
        <div className='container-fluid fluid-small'>
            <div className='text-center'>
                <h3>
                    <FormattedMessage id='marketplace.preview.confirmation' />
                </h3>
            </div>
            <div className='Preview panel panel-default'>
                <div className='panel-body'>
                    {showBillingAndPaymentDetails && (
                        <>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <h5>
                                        <FormattedMessage id='marketplace.preview.billingAddress' />
                                    </h5>
                                    <BillingAddressContainer />
                                </div>
                                <div className='col-sm-6'>
                                    <h5>
                                        <FormattedMessage id='marketplace.preview.paymentMethod' />
                                    </h5>
                                    <PaymentInformationPreviewContainer />
                                </div>
                            </div>
                            <hr />
                        </>
                    )}

                    <div>
                        <h5>
                            <FormattedMessage id='marketplace.preview.selectedProduct' />
                        </h5>
                        <div className={'margin-bottom-20'}>
                            <SelectedResourcesOverviewContainer />
                        </div>
                        <MultiLevelPriceDetailsContainer />
                    </div>

                    {showFreeTrialWarning && <CheckoutFreeTrialWarning />}

                    <div
                        className={`display-flex justify-content-end padding-top-20 ${
                            isFleetProduct ? 'padding-right-15' : 'padding-bottom-20'
                        }`}
                    >
                        <Checkbox
                            className='checkboxTerms'
                            checked={termsAccepted}
                            onClick={() => {
                                setTermsAccepted(!termsAccepted);
                                safeDataLayerPush(() => getGAEventTermsAccepted(gAData));
                            }}
                        >
                            <FormattedMessage id='marketplace.termsAndConditionCheckPreText' />
                            <TermsAndConditionsContainer serviceSku={product.sku} level={productLevel}>
                                <span className='link'>
                                    <FormattedMessage id={'marketplace.termsAndConditionCheckLinkText'} />
                                </span>
                            </TermsAndConditionsContainer>
                            <FormattedMessage id='marketplace.termsAndConditionCheckPostTextSingle' />
                        </Checkbox>
                    </div>
                    {isFleetProduct && (
                        <div
                            className='display-flex justify-content-end padding-bottom-20 padding-top-10'
                            style={{ paddingRight: '1px' }}
                        >
                            <Checkbox
                                className='checkboxTerms'
                                checked={privacyPolicyAccepted}
                                onClick={() => setPrivacyPolicyAccepted(!privacyPolicyAccepted)}
                            >
                                <FormattedMessage id='marketplace.termsAndConditionCheckPreText' />
                                <PrivacyPolicyOpener
                                    linkText={intl.formatMessage({ id: 'marketplace.privacyPolicies' })}
                                />
                                <FormattedMessage id='marketplace.termsAndConditionCheckPostTextSingle' />
                            </Checkbox>
                        </div>
                    )}

                    <div className='display-flex justify-content-between flex-wrap'>
                        <PreviousButton previousPath={previousPath} />
                        <CheckoutBuyButton
                            termsAccepted={isFleetProduct ? termsAccepted && privacyPolicyAccepted : termsAccepted}
                            onBuyClick={() => {
                                performCheckout();
                                navigate(nextPath);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    const product = getSelectedProduct(state);
    const showFreeTrialWarning =
        getIsEligibleForFreeTrial(state) && product && isFreeTrialProduct(state, product.sku) && !isPurchaseFree(state);
    return {
        product,
        productLevel: getSelectedProductLevel(state),
        resourceIds: getSelectedResourceIds(state),
        gAData: getGACheckoutStepData(state, CheckoutSteps.ORDER_PREVIEWED),
        showFreeTrialWarning,
        showBillingAndPaymentDetails: !isBrazilianTenant(state),
    };
};

const mapDispatchToProps = (dispatch: RootDispatch) => ({
    performCheckout: () => dispatch(subscribeThunk),
});

export const CheckoutPreviewPageContainer = compose<React.ComponentType<OwnProps>>(
    withLoadingAndErrorState({
        hasErrorSelector: (state) => getHasError(state, ApiCalls.CHECKOUT_PRODUCT_DETAILS),
        isLoadingSelector: (state) =>
            !getSelectedProduct(state) || getIsLoading(state, ApiCalls.CHECKOUT_PRODUCT_DETAILS),
        reloadAction: (dispatch) => dispatch(fetchBookableResourcesThunk),
    }),
    connect(mapStateToProps, mapDispatchToProps)
)(CheckoutPreviewPage);
