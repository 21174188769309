import { FormattedMessage } from 'react-intl';
import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';
import { getManageContractsLink } from '../customerCenterTabService';
import { useNavigate } from 'react-router-dom';

interface Props {
    closeDialog: () => void;
}

export const AssetOverviewGotoContractsDialog = (props: Props) => {
    const { closeDialog } = props;
    const navigate = useNavigate();
    const content = (
        <div>
            <div className='confirmationDialogText'>
                <FormattedMessage id={'marketplace.assetOverview.gotoManageContractsDisclaimer'} />{' '}
            </div>
        </div>
    );
    return (
        <ConfirmationDialog
            show={true}
            title={<FormattedMessage id={'marketplace.assetOverview.gotoManageContracts.headline'} />}
            content={content}
            onClickConfirm={() => navigate(getManageContractsLink())}
            onClickCancel={closeDialog}
            cancelButtonText={<FormattedMessage id={'marketplace.navigations.back'} />}
            confirmButtonText={<FormattedMessage id={'marketplace.myServices.manageContracts'} />}
        />
    );
};
