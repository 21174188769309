import { useEffect, useState } from 'react';
import LoadingErrorState from '../common/error/LoadingErrorState';
import withPermissionValidator from '../common/permissions/withPermissionValidator';
import { compose } from 'redux';
import { PermissionValidatorWrappedComponentProps } from '../common/permissions/PermissionValidatorWrapper';
import { SUBSCRIPTION_WRITE } from '../common/permissions/permissions';
import { UnauthorizedDialog } from '../common/error/UnauthorizedDialog';
import { useLocation } from 'react-router-dom';
import { resourceSelectionActions } from './resourceSelection/redux/resourceSelection.redux';
import { connect } from 'react-redux';
import { getHasError, getIsLoading } from '../api/redux/api.redux';
import { ApiCalls } from '../api/redux/types';
import { CheckoutServiceError } from './components/CheckoutServiceError';
import { checkoutActions, getSelectedProductId } from './redux/checkout.redux';
import { fetchPaymentDetailsIfNecessaryByTenant } from '../common/payment/paymentMethods.thunk';
import { Checkout3StepsContainer } from './Checkout3Steps';
import { isServiceCareProduct } from './components/RedirectToWorkshopAssignment';
import { Checkout4StepsContainer } from './Checkout4Steps';
import { fetchBillingAddressIfNecessaryByTenant } from '../common/billing/billingAddress.thunk';
import { MaintenanceView } from '../common/MaintenanceView';
import { isProductInMaintenanceMode } from '../../../configuration/featureToggle/featureToggleService';
import { useCheckoutDeepLinking } from './deeplinking/checkoutDeepLinkingHook';
import { RootDispatch, RootState } from '../../../configuration/setup/store';
import { isSelectedProductBookable } from './CheckoutService';
import { CheckoutNoServiceSelected } from './components/CheckoutNoServiceSelected';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { getIsOpenedInPopup } from '../popup/popup.redux';
import { Checkout2StepsContainer } from './Checkout2Steps';
import { isBrazilianTenant } from '../common/utils/tenants';

interface Props extends PermissionValidatorWrappedComponentProps {
    hasProductError: boolean;
    isOpenedInPopup: boolean;
    fetchPaymentDetails: () => void;
    productId: string;
    resetCheckoutState: () => void;
    isProductInMaintenance: (productId?: string) => boolean;
    isProductBookable: boolean;
    isLoading: boolean;
    showBrazilianCheckout: boolean;
}

function useStepNumberTracking(pathname: string) {
    let stepNumber = 0;
    const numberExtractedFromPath = Number(pathname.substring(pathname.lastIndexOf('/') + 1));
    if (!Number.isNaN(numberExtractedFromPath)) {
        stepNumber = numberExtractedFromPath - 1;
    }
    const [maximumVisitedStep, setMaximumVisitedStep] = useState(0);
    useEffect(() => {
        if (stepNumber > maximumVisitedStep) {
            setMaximumVisitedStep(stepNumber);
        }
    }, [stepNumber, maximumVisitedStep, setMaximumVisitedStep]);
    return { stepNumber, maximumVisitedStep };
}

const Checkout = ({
    fetchPaymentDetails,
    hasProductError,
    userHasPermissions,
    productId,
    resetCheckoutState,
    isOpenedInPopup,
    isProductInMaintenance,
    isProductBookable,
    isLoading,
    showBrazilianCheckout,
}: Props) => {
    const { pathname } = useLocation();
    const { stepNumber, maximumVisitedStep } = useStepNumberTracking(pathname);

    useCheckoutDeepLinking();

    useEffect(() => {
        fetchPaymentDetails();
    }, [fetchPaymentDetails]);

    useEffect(() => {
        return () => resetCheckoutState();
    }, [resetCheckoutState]);

    if (isProductInMaintenance(productId)) {
        return <MaintenanceView />;
    }
    if (isLoading) {
        return <Spinner />;
    }
    if (hasProductError) {
        return <CheckoutServiceError />;
    }

    if (!userHasPermissions([SUBSCRIPTION_WRITE])) {
        return <UnauthorizedDialog onClose={isOpenedInPopup ? () => window.close() : undefined} />;
    }

    if (!isProductBookable) {
        return <CheckoutNoServiceSelected />;
    }

    if (showBrazilianCheckout) {
        return <Checkout2StepsContainer stepNumber={stepNumber} maximumVisitedStep={maximumVisitedStep} />;
    }
    return isServiceCareProduct(productId) ? (
        <Checkout4StepsContainer stepNumber={stepNumber} maximumVisitedStep={maximumVisitedStep} />
    ) : (
        <Checkout3StepsContainer stepNumber={stepNumber} maximumVisitedStep={maximumVisitedStep} />
    );
};

const mapStateToProps = (state: RootState) => ({
    hasProductError: getHasError(state, ApiCalls.CHECKOUT_PRODUCT_DETAILS),
    productId: getSelectedProductId(state),
    isOpenedInPopup: getIsOpenedInPopup(state),
    isProductInMaintenance: isProductInMaintenanceMode(state),
    isProductBookable: getIsLoading(state, ApiCalls.CHECKOUT_PRODUCT_DETAILS) || isSelectedProductBookable(state),
    isLoading:
        getIsLoading(state, ApiCalls.CHECKOUT_PRODUCT_DETAILS) || getIsLoading(state, ApiCalls.RESOURCE_SELECTION),
    showBrazilianCheckout: isBrazilianTenant(state),
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
    fetchPaymentDetails: () => {
        dispatch(fetchPaymentDetailsIfNecessaryByTenant());
        dispatch(fetchBillingAddressIfNecessaryByTenant());
    },
    resetCheckoutState: () => {
        dispatch(resourceSelectionActions.resetResourceSelectionState());
        dispatch(checkoutActions.resetSelectedProductState());
    },
});

export const CheckoutContainer = compose<React.ComponentType>(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissionValidator(LoadingErrorState)
)(Checkout);
