import 'iframe-resizer/js/iframeResizer.contentWindow'; // Required for resizing when included as iframe
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { freeTrialStatusThunk } from './freeTrialStatus.thunk';
import { getIsEligibleForFreeTrial, isFreeTrialExpiringInLessThan30Days } from './redux/freeTrial.redux';
import { FreeTrialButton } from './FreeTrialButton';
import manPointingUp from './images/man_pointing_up.webp';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../../configuration/setup/store';
import { FreeTrialTeaser } from './FreeTrialTeaser';

export function FreeTrialButtonWrapper() {
    const showButton = useSelector(getIsEligibleForFreeTrial);
    const shouldDisplayTeaser = useSelector(isFreeTrialExpiringInLessThan30Days);
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const image = searchParams.get('image');

    useEffect(() => {
        dispatch(freeTrialStatusThunk);
    }, []);

    if (!showButton && !shouldDisplayTeaser) {
        return <div style={{ height: 0 }} data-testid={'emptyDiv'} />;
    }

    if (shouldDisplayTeaser) {
        return <FreeTrialTeaser />;
    }

    if (image === 'false') {
        return (
            <div className='buyButton'>
                <FreeTrialButton />
            </div>
        );
    }
    return (
        <div className='bg-primary padding-x-20 padding-top-20 position-relative overflow-hidden'>
            <div className='width-90pct rounded-circle bg-white aspect-ratio-1 position-absolute top-0 left-50pct translate-x-50pct-y-50pct opacity-50 blur-50' />
            <div className='buyButton'>
                <FreeTrialButton />
            </div>
            <img
                className='img-responsive margin-x-auto margin-top-20 position-relative max-width-150'
                src={manPointingUp}
                alt={'Man pointing up'}
            />
        </div>
    );
}
