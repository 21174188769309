import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { getCustomerCenterPath } from '../common/routes';
import { InvoicesContainer } from './invoices/InvoicesContainer';
import { ServiceOverviewContainer } from './serviceOverview/ServiceOverviewContainer';
import { UnauthorizedDialog } from '../common/error/UnauthorizedDialog';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { CustomerCenterTab, TabInfo } from './types';
import { HistoryContainer } from './history/HistoryContainer';
import { PaymentMethodsContainer } from './PaymentMethodsContainer';
import { Usage } from './usage/Usage';
import { Navigate, Route, Routes } from 'react-router-dom';
import LoadingErrorStateWithReload from '../common/error/LoadingErrorStateWithReload';
import { getPermissions } from '../common/permissions/redux/permissions.redux';
import { getHasError, getIsLoading } from '../api/redux/api.redux';
import { ApiCalls } from '../api/redux/types';
import { getAllVisibleCustomerCenterTabs, getDefaultCustomerCenterTab } from './customerCenterTabService';
import { connect } from 'react-redux';
import { fetchPermissionsThunk } from '../common/permissions/permissions.thunk';
import { AssetOverviewContainer } from './assetOverview/AssetOverviewContainer';
import { ContractPageContainer } from './contracts/ContractPageContainer';
import { ProfileTab } from './profile/ProfileTab';
import { PartnersContainer } from './partners/PartnersContainer';
import { RootDispatch, RootState } from '../../../configuration/setup/store';
import { FreeTrialOverviewContainer } from './freeTrial/FreeTrialOverviewContainer';
import { InboundConnectionsTab } from './inbound/InboundConnectionsTab';

interface Props {
    permissions?: Array<string>;
    hasError: boolean;
    isLoading: boolean;
    fetchPermissions: () => void;
    defaultTab: CustomerCenterTab | undefined;
    visibleTabs: Array<TabInfo>;
}

export function CustomerCenter(props: Props) {
    const { defaultTab, fetchPermissions, visibleTabs, isLoading, hasError } = props;

    useEffect(() => {
        fetchPermissions();
    }, [fetchPermissions]);
    if (isLoading) {
        return <Spinner text={<FormattedMessage id={'marketplace.loading'} />} isInverse={false} />;
    }

    if (hasError) {
        return (
            <LoadingErrorStateWithReload
                headline={'marketplace.customerCenter.loading.error'}
                onReload={fetchPermissions}
            />
        );
    }

    if (visibleTabs.length <= 0 || !defaultTab) {
        return <UnauthorizedDialog />;
    }

    const containsFreeTrial = visibleTabs.some((tab) => tab.componentName === CustomerCenterTab.FREE_TRIAL);
    const bookingTab = containsFreeTrial ? CustomerCenterTab.FREE_TRIAL : CustomerCenterTab.SERVICES;

    return (
        <div className={'CustomerCenter'}>
            <Routes>
                <Route path={CustomerCenterTab.SERVICES} element={<ServiceOverviewContainer />} />
                <Route path={CustomerCenterTab.ASSETS} element={<AssetOverviewContainer />} />
                <Route path={CustomerCenterTab.CONTRACTS} element={<ContractPageContainer />} />
                <Route path={CustomerCenterTab.INVOICES} element={<InvoicesContainer />} />
                <Route path={CustomerCenterTab.HISTORY} element={<HistoryContainer />} />
                <Route path={CustomerCenterTab.PROFILE} element={<ProfileTab />} />
                <Route path={CustomerCenterTab.PAYMENT_METHODS} element={<PaymentMethodsContainer />} />
                <Route path={CustomerCenterTab.USAGE} element={<Usage />} />
                <Route
                    path={CustomerCenterTab.PARTNERS}
                    element={<Navigate replace to={getCustomerCenterPath(CustomerCenterTab.INBOUND)} />}
                />
                <Route path={`${CustomerCenterTab.INBOUND}/*`} element={<InboundConnectionsTab />} />
                <Route path={CustomerCenterTab.OUTBOUND} element={<PartnersContainer />} />
                <Route path={CustomerCenterTab.FREE_TRIAL} element={<FreeTrialOverviewContainer />} />
                <Route
                    path={CustomerCenterTab.BOOKINGS}
                    element={<Navigate replace to={getCustomerCenterPath(bookingTab)} />}
                />
                <Route path='/' element={<Navigate replace to={getCustomerCenterPath(defaultTab)} />} />
            </Routes>
        </div>
    );
}

export function mapStateToProps(state: RootState) {
    return {
        permissions: getPermissions(state),
        isLoading: getIsLoading(state, ApiCalls.PERMISSIONS),
        hasError: getHasError(state, ApiCalls.PERMISSIONS),
        visibleTabs: getAllVisibleCustomerCenterTabs(state),
        defaultTab: getDefaultCustomerCenterTab(state),
    };
}

export function mapDispatchToProps(dispatch: RootDispatch) {
    return {
        fetchPermissions: () => dispatch(fetchPermissionsThunk),
    };
}

export const CustomerCenterContainer = connect(mapStateToProps, mapDispatchToProps)(CustomerCenter);
