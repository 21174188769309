import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { parseQueryString } from '../common/utils/qsUtil';
import { redirectIfCategoryMatches } from './redirectToOpenMarketplace';
import { getLocale } from '../../../configuration';
import { getTenant, Tenant } from '../common/utils/tenants';
import { RootState } from '../../../configuration/setup/store';

export const ProductsOverviewRedirectComponent = (props: { tenant: Tenant; locale: string }) => {
    const { search } = useLocation();
    const { categories } = parseQueryString(search);
    redirectIfCategoryMatches(categories as string | undefined, props.locale, props.tenant);
    return <div />;
};

const mapStateToProps = (state: RootState) => ({
    tenant: getTenant(state),
    locale: getLocale(state),
});

export const ProductsOverviewRedirect = connect(mapStateToProps)(ProductsOverviewRedirectComponent);
