import { connect } from 'react-redux';
import { getLocale } from '../../../configuration';
import { redirectIfSkuMatches } from './redirectToOpenMarketplace';
import { getTenant, Tenant } from '../common/utils/tenants';
import { RootState } from '../../../configuration/setup/store';
import { useLocation } from 'react-router-dom';

const ProductDetailsRedirectComponent = (props: { locale: string; tenant: Tenant }) => {
    const { pathname } = useLocation();
    const pathParts = pathname.split('/');
    const sku = pathParts[pathParts.length - 1];
    redirectIfSkuMatches(sku, props.locale, props.tenant);
    return null;
};

const mapStateToProps = (state: RootState) => ({
    tenant: getTenant(state),
    locale: getLocale(state),
});

export const ProductDetailsRedirect = connect(mapStateToProps)(ProductDetailsRedirectComponent);
