import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';
import {
    DisplayMessages,
    getDisplayMessages,
    getLocale,
    getUserAccount,
    getUserTenant,
    isUserSessionExpired,
} from '../configuration';
import { initToggles } from './app/common/toggles/featureToggles';
import { MarketplaceContainer } from './app/MarketplaceContainer';
import { AppHeaderContainer } from './AppHeaderContainer';
import { fetchPermissionsThunk } from './app/common/permissions/permissions.thunk';
import { useMarketplaceBottomBar } from './app/customerCenter/MarketplaceBottomBar';
import { AppSubNavigationContainer } from './AppSubNavigationContainer';
import './app/common/IntlDateTimePicker';
import { RootDispatch, RootState, useAppDispatch } from '../configuration/setup/store';
import { usePopupDeepLinkingHook } from './app/popup/popupDeepLinkingHook';
import { getIsOpenedInPopup } from './app/popup/popup.redux';
import { freeTrialStatusThunk } from './app/freeTrial/freeTrialStatus.thunk';

// Initialize extra-globals and toggles
initToggles();

interface AppContainerProps {
    fetchPermissions: () => void;
    userLocale: string;
    userAccountId?: string;
    userTenant?: string;
    showSessionExpired: boolean;
    displayMessages: DisplayMessages;
    isOpenedInPopup: boolean;
}

function App(props: AppContainerProps) {
    const {
        displayMessages,
        showSessionExpired,
        userLocale: locale,
        userAccountId,
        userTenant,
        isOpenedInPopup,
        fetchPermissions,
    } = props;

    useEffect(() => {
        verifyAndRedirectToLatamMarketplace(userAccountId, userTenant);
    }, [userAccountId]);

    useEffect(() => {
        fetchPermissions();
    }, [fetchPermissions]);
    usePopupDeepLinkingHook();

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(freeTrialStatusThunk);
    }, []);

    const MarketplaceBottomBar = useMarketplaceBottomBar();
    const [forcedClose, setForcedClose] = useState(false);

    if (!displayMessages) {
        return null;
    }

    return (
        <ApplicationLayout>
            {!isOpenedInPopup && <AppHeaderContainer />}
            <ApplicationLayout.Body
                bottomBar={MarketplaceBottomBar}
                banner={
                    !isOpenedInPopup && (
                        <>
                            <AppSubNavigationContainer />
                        </>
                    )
                }
            >
                <NotificationsContainer />
                <SessionExpiredDialog
                    locale={locale}
                    onClose={() => setForcedClose(true)}
                    show={showSessionExpired && !forcedClose}
                />
                <MarketplaceContainer />
            </ApplicationLayout.Body>
        </ApplicationLayout>
    );
}

// TODO MANWS-10433: Remove the following list, as all brazil users should be redirected after roll-out
const latamCustomerCenterAccounts = ['ecf171b3-f2ef-48a4-8a71-83a55d03ec3f', '45d26957-0e23-4c41-833b-1eab0a017aa1'];

const verifyAndRedirectToLatamMarketplace = (accountId?: string, tenant?: string) => {
    const currentPath = window.location.pathname;

    // Consider redirect only for brazil tenant
    if (tenant?.startsWith('rio-brazil.')) {
        // redirect toggled accounts to latam customer center
        if (latamCustomerCenterAccounts.some((account) => account === accountId) && !currentPath.includes('/checkout')) {
            const currentQueryString = window.location.search;
            const targetURL = 'https://latam-marketplace.rio.cloud';
            window.location.href = targetURL + currentPath + currentQueryString;
        }

        // redirect toggled accounts to latam checkout
        if (currentPath.includes('/checkout')) {
            const currentQueryString = window.location.search;
            const targetURL = 'https://latam-marketplace.rio.cloud';
            window.location.href = targetURL + currentPath + currentQueryString;
        }
    }
};

export const mapDispatchToProps = (dispatch: RootDispatch) => ({
    fetchPermissions: () => dispatch(fetchPermissionsThunk),
});

export const mapStateToProps = (state: RootState) => ({
    displayMessages: getDisplayMessages(state),
    userAccountId: getUserAccount(state),
    userTenant: getUserTenant(state),
    showSessionExpired: isUserSessionExpired(state),
    userLocale: getLocale(state),
    isOpenedInPopup: getIsOpenedInPopup(state),
});

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);
